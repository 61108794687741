<template>
  <div id="app">
    <AConfigProvider :locale="locale">
      <router-view></router-view>
    </AConfigProvider>
    <div class="common-spin" v-if="loadingProps.spinning">
      <a-spin :spinning="true">
      </a-spin>
    </div>
  </div>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN.js"
import Vue from "vue"; 

export default {
  name: 'App',
  data() {
    return {
      locale: zhCN,
      loadingProps: {
        spinning: false
      }
    }
  },
  beforeCreate() {
    Vue.prototype.$app = this
  }
}
</script>

<style lang="less">
:root {
  body {
    color: #4D4D4D;
    background-color: #F8F8F8;
    font-size: 16px;
  }
}
#app {
  font-size: 16px;
  color: #4D4D4D;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
}

.common-spin {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgba(255,255,255,.5);
}
</style>
