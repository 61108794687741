import axios from "axios";
import Vue from 'vue'
import router from "@/router";
import { message } from "ant-design-vue";
import appsettings from "@/utils/appsettings.js";

axios.defaults.baseURL = appsettings.apiroot;
axios.defaults.timeout = 50000;

// 内存中正在请求的数量
let loadingNum = 0;

export function startLoading() {
  if (loadingNum === 0) {
    Vue.prototype.$setLoading(true)
  }
  loadingNum++;
}

export function endLoading() {
  // 请求数量减1
  loadingNum--;
  if (loadingNum <= 0) {
    Vue.prototype.$setLoading(false)
  }
}


axios.defaults.withCredentials = false;
axios.interceptors.request.use(
  function (config) {
    config.metadata = { startTime: new Date() };
    if (config.url !== '/data/issue') {
      startLoading();
    }
    return config;
  },
  function (error) {
    message.error('请求失败！');
    endLoading();
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    endLoading();
    response.config.metadata.endTime = new Date();
    response.duration =
      response.config.metadata.endTime - response.config.metadata.startTime;
    return response;
  },
  function (error) {
    endLoading();
    return Promise.reject(error);
  }
);

const fetch = (method, url, params = {}, uploadProcess) => {

  let token = localStorage.getItem("token") ? localStorage.getItem("token") : "edp-token"
  let headers = { "Content-Type": "application/json" }

  if (params instanceof FormData) {
    params.append("token", token)
    params.append("platform", "web")
    headers["Content-Type"] = "application/x-www-form-urlencoded"
  } else if (typeof params === 'object' && !Array.isArray(params)) {
    params.token = token
    params.platform = "web"
  }

  const config = {
    baseURL: Vue.prototype.$appsettings.apiroot,
    method: method.toLocaleUpperCase() || "POST",
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // },
    headers: headers,
    url,
    onUploadProgress: progressEvent => { // 文件上传进度条回调
      let percent = (progressEvent.loaded / progressEvent.total * 100 | 0)
      uploadProcess && uploadProcess(percent)
    }
  };

  if (method === "GET" || method === "DELETE") {
    config.params = params;
  } else {
    config.data = params;
  }
  if (!config.url) {
    return;
  }
  config.data = config.data || {};
  // config.data = qs.stringify(config.data)

  if (config.method === "DELETE") {
    config.data = {};
  }
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        let duration = res.duration;
        // res = JSON.parse(res.data)
        res.apiDuration = duration;
        // console.log(res)
        if (res.data.status === 0) {
          resolve(res.data);
        } else if (res.data.status === 9999) {
          localStorage.removeItem("token");
          router.push({ path: "/login" });
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        endLoading();
        reject(err);
      });
  });
};

export {
  fetch
};
