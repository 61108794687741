let envKey = "prod_kunda"

const appsettings = {
  pre: {
    apiroot: 'http://edp-pre.gyh01.com:10160/',
    imgPrefix: 'http://tstdat.to2025.com/edp/assets/icon/',
    fontPrefix: 'http://tstdat.to2025.com/edp/assets/font/'
  },
  // 工业汇 生产
  prod: {
    apiroot: '/api/',
    imgPrefix: 'https://dat.to2025.com/edp_v2/assets/icon/',
    fontPrefix: 'https://dat.to2025.com/edp_v2/assets/font/'
  },
  // DEMO
  prod_demo: {
    apiroot: '/api/',
    imgPrefix: 'https://dat.to2025.com/edp_v2/assets/icon/',
    fontPrefix: 'https://dat.to2025.com/edp_v2/assets/font/'
  },
  // 白鲸
  prod_bj: {
    apiroot: '/api/',
    imgPrefix: 'https://dat.to2025.com/edp_bj/assets/icon/',
    fontPrefix: 'https://dat.to2025.com/edp_bj/assets/font/'
  },
  // 坤达生产
  prod_kunda: {
    apiroot: '/api/',
    imgPrefix: 'https://dat.to2025.com/edp_kunda/assets/icon/',
    fontPrefix: 'https://dat.to2025.com/edp_kunda/assets/font/'
  },
  // 白鲸本地环境
  prod_bj_live: {
    apiroot: '/api/',
    imgPrefix: 'http://192.168.3.73:2425/api/oss/dat/edp/ua/assets/icon/',
    fontPrefix: 'http://192.168.3.73:2425/api/oss/dat/edp/ua/assets/font/'
  }
}[envKey]

module.exports = {
  ...appsettings,
  envKey
}
