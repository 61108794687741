import store from "@/store";


const mixin = {
  computed: {
    allPermissions() {
      return this.$store.state.prj_permission
    }
  }
}
export default function(Vue) {
  Vue.mixin(mixin)
  Vue.prototype.$hasP = function(code, prjoid = null) {
    if (!prjoid) {
      prjoid = this.$route.query.prj_oid
    }
    let find = this.$store.state.prj_permission.find(item => item.prjoid === prjoid)
    if (!find) {
      return false
    }
    return find.permissions.includes(code)
  }
  Vue.prototype.$getP = function (prjoid = null) {
    if (!prjoid) {
      prjoid = this.$route.query.prj_oid
    }
    let find = store.state.prj_permission.find(item => item.prjoid === prjoid)
    if (!find) {
      return []
    }
    return find.permissions
  }
}
