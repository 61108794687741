<template>
  <keep-alive include="reportForm">
    <router-view></router-view>
  </keep-alive>
</template>

<script>
export default {
  name: "reportFormParent"
}
</script>

<style scoped>

</style>