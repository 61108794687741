import vuex from "vuex"
import Vue from "vue";
import {fetch} from "@/utils/request";

Vue.use(vuex)
const store = new vuex.Store({
  state() {
    return {
      prj_permission: []
    }
  },
  actions: {
    getPrjPermissions(store, prjoid) {
      console.log("request")
      return fetch("post", "prj/functions", {oid: prjoid}).then(res => {
        if (res.status === 0) {
          let index = store.state.prj_permission.findIndex(item => item.prjoid === prjoid)
          if (index === -1) {
            store.state.prj_permission.push({
              prjoid,
              permissions: []
            })
            index = store.state.prj_permission.length - 1
          }
          store.state.prj_permission[index].permissions = res.data.split(',')
        }
      })
    }
  }
})
Vue.prototype.$store = store

export default store