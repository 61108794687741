import VueRouter from "vue-router"
import Vue from "vue";
import emptyRouterView from "@/components/emptyRouterView.vue"
import projectParent from "@/view/project/projectParent.vue";
import reportFormParent from "@/view/reportForm/reportFormParent.vue";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "hash",
  routes: [
    {
      // 登录页
      path:"/login",
      component: () => import("@/view/login/login"),
    },
    {
      // 后台页面
      path: "/",
      component: () => import("@/view/layout.vue"),
      redirect: "/workspace",
      children: [
        {
          path: "/workspace",
          component: emptyRouterView,
          redirect: "/workspace/workitem",
          meta: {
            title: "工作台",
            menus: [
              { title: "任务项", to: "/workspace/workitem"},
              { title: "我的项目", to: "/workspace/project", blackRoles: ['supplier']},
              { title: "仪表盘", to: "/workspace/dashboard"},
              { title: "审批", to: "/workspace/approval"},
              { title: "工时", to: "/workspace/workTimeLog"},
              { title: '汇报', to: "/workspace/review", blackRoles: ['supplier']}
            ]
          },
          children: [
            // 任务项
            { path: "/workspace/workitem", component: () => import("@/view/workspace/workitem.vue") },
            // 我的项目
            { path: "/workspace/project", component: () => import("@/view/project/projectList.vue"), props: { default_status: "0" } },
            // 仪表盘
            { path: "/workspace/dashboard", component: () => import("@/view/workspace/dashboard.vue") },
            // 审批
            { path: "/workspace/approval", component: () => import("@/view/workspace/approval.vue") },
            // 工时
            { path: "/workspace/workTimeLog", component: () => import("@/view/workspace/workTimeLog.vue") },
            // 汇报
            {
              path: "/workspace/review",
              component: emptyRouterView,
              redirect: "/workspace/review/list",
              meta: {
                hideSubmenu: true,
                submenu: [
                  {title: "列表", to: '/workspace/review/list'},
                  {title: "编辑", to: '/workspace/review/form'},
                ]
              },
              children: [
                { path: "/workspace/review/list", component: () => import("@/view/workspace/review/reviewList.vue") },
                { path: "/workspace/review/form", component: () => import("@/view/workspace/review/reviewForm.vue"), meta: {fill: false} },
              ]
            },
          ]
        },
        // 项目列表 单独的页面
        {
          name: 'projectList',
          path: "/project/list",
          component: () => import("@/view/project/projectList.vue"),
          props: {
            default_status: "100"
          },
          meta: {
            title: '项目管理',
            menus: [
              {title: "项目", to: "/project/list"}
            ]
          }
        },
        {
          path: "/project",
          component: projectParent,
          name: 'projectParent',
          meta: {
            title: "项目管理",
            menus: [
              {title: "总览", icon: "overview", code: "M_OVERVIEW", to: route=> `/project/overview?prj_oid=${route.query.prj_oid}`},
              {title: "项目计划与进度", icon: "plan", code: "M_SCHEDULE", to: (route)=>{return `/project/plan?prj_oid=${route.query.prj_oid}`}},
              {title: "阶段", icon: "stage", code: "M_PHASE", to: route => `/project/stage?prj_oid=${route.query.prj_oid}`},
              {title: "任务", icon: "task", to: route=> `/project/task?prj_oid=${route.query.prj_oid}`},
              {title: "产品", icon: "product", to: route => `/project/product?prj_oid=${route.query.prj_oid}`},
              {title: "审批", icon: "approval", to: route=> `/project/approval?prj_oid=${route.query.prj_oid}`},
              {title: "财务", icon: "moneyLog", code: "M_FINANCE", to: route => `/project/moneyLog?prj_oid=${route.query.prj_oid}`},
              {title: "报表", icon: "report", code: "M_REPORT", to: route => `/project/report?prj_oid=${route.query.prj_oid}&active_tab=${route.query.active_tab || ''}`},
              {title: "文档", icon: "document", code: "M_DOC", to: route => `/project/document?prj_oid=${route.query.prj_oid}`},
              {title: "成员", icon: "member", code: "M_MEMBER", to: route => `/project/member?prj_oid=${route.query.prj_oid}`},
              {title: "设置", icon: "setting", code: "M_SETTING", to: (route)=>{return `/project/setting?prj_oid=${route.query.prj_oid}`}},
              // {title: "需求", to: route=> `/project/demand?prj_oid=${route.query.prj_oid}`},
            ]
          },
          children: [
            { path: "/project/approval", component: () => import("@/view/project/projectApproval.vue") },
            { path: "/project/overview", component: () => import("@/view/project/projectOverView.vue"), meta: {fill: false} },
            // { path: "/project/demand", component: () => import("@/view/project/projectDemand.vue") },
            { path: "/project/task", component: () => import("@/view/project/projectTask.vue") },
            { path: "/project/product", component: () => import("@/view/project/projectProduct.vue") },
            { path: "/project/moneyLog", component: () => import("@/view/project/projectMoneyLog.vue") },
            { path: "/project/document", component: () => import("@/view/project/projectDocument.vue") },
            { path: "/project/report", component: () => import("@/view/project/projectReport.vue")},
            { path: "/project/report/detail", component: () => import("@/view/project/report/detail.vue"), meta: {fill: false }},
            { path: "/project/report/money", component: () => import("@/view/project/projectReportMoney.vue") },
            { path: "/project/member", component: () => import("@/view/project/projectMember.vue") },
            { path: "/project/stage", component: () => import("@/view/project/projectStage.vue") },
            {
              path: "plan", // 第三层嵌套
              component: emptyRouterView,
              redirect: "/project/plan/list",
              meta: {
                submenu: [
                  {title: "项目计划", to: (route)=>{return `/project/plan/list?prj_oid=${route.query.prj_oid}`}},
                  {title: "里程碑", to: (route)=>{return `/project/plan/milestone?prj_oid=${route.query.prj_oid}`}},
                  {title: "交付物", to: (route)=>{return `/project/plan/deliverable?prj_oid=${route.query.prj_oid}`}}
                ]
              },
              children: [
                {path: 'list', component: () => import("@/view/project/projectPlanList.vue")},
                {path: 'milestone', component: () => import("@/view/project/projectPlanMilestone.vue")},
                {path: 'deliverable', component: () => import("@/view/project/projectPlanDeliverable.vue")},
                {path: 'milestoneDetails', component: () => import("@/view/project/projectPlanMilestoneDetails")}, //里程碑详情
                {path: 'deliverableDetails', component: () => import("@/view/project/projectPlanDeliverableDetails")} //产品交付物详情
              ]
            },
            {
              path: "setting", // 第三层嵌套
              component: emptyRouterView,
              redirect: "/project/setting/projectSetting",
              meta: {
                submenu: [
                  {title: "项目信息", to: (route)=>{return `/project/setting/projectSetting?prj_oid=${route.query.prj_oid}`}},
                  {title: "交付物", to: (route)=>{return `/project/setting/projectSettingDeliver?prj_oid=${route.query.prj_oid}`}},
                  {title: "项目操作",  to: (route)=>{return `/project/setting/projectsetOperation?prj_oid=${route.query.prj_oid}`}}
                ]
              },
              children: [
                {path: 'projectSetting', component: () => import("@/view/project/projectSetting.vue")},
                {path: 'projectSettingDeliver', component: () => import("@/view/project/projectSettingDeliver.vue")},
                {path: 'projectsetOperation', component: () => import("@/view/project/projectsetOperation.vue"), meta: {fill: false}},
              ]
            },
          ]
        },
        {
          name: 'supplierProject',
          path: '/supplierProject',
          component: () => import("@/view/supplier/supplierProject.vue"),
          meta: {
            title: "项目协作"
          }
        },
        {
          path: "/supplier",
          name: 'supplierParent',
          component: () => import("@/view/supplier/supplierParent.vue"),
          meta: {
            title: "项目协作",
            menus: [
              {title: "计划与进度", icon: "plan", to: route => `/supplier/plan?prj_oid=${route.query.prj_oid}`},
              {title: "任务", icon:"task", to: route=> `/supplier/task?prj_oid=${route.query.prj_oid}`},
              {title: "产品", icon:"product", to: route => `/supplier/product?prj_oid=${route.query.prj_oid}`},
              {title: "文档", icon:"document", to: route => `/supplier/document?prj_oid=${route.query.prj_oid}`},
            ]
          },
          children: [
            { path: "/supplier/plan", component: () => import("@/view/supplier/supplierPlan.vue"), meta: {fill: false} },
            { path: "/supplier/task", component: () => import("@/view/supplier/supplierTask.vue") },
            { path: "/supplier/product", component: () => import("@/view/supplier/supplierProduct.vue") },
            { path: "/supplier/document", component: () => import("@/view/supplier/supplierDocument.vue") },
          ]
        },
        {
          name: 'digitalAssets',
          path: "/digitalAssets/Digital",
          component: () => import("@/view/digitalAssets/Digital.vue"),
          meta: {
            title: "数据资产",
          }
        },
        {
          path: "/product",
          component: emptyRouterView,
          redirect: '/product/productManage',
          meta: {
            title: "产品管理",
            menus: [
              { title: "产品", to: "/product/productManage"},
              { title: "库存", to: "/product/productInventory", blackRoles: ['supplier']},
              { title: "采购", to: "/product/productPurchase", blackRoles: ['supplier']},
            ]
          },
          children: [
            { path: "/product/productManage", component: () => import("@/view/product/productManage.vue") },
            { path: "/product/productInventory", component: () => import("@/view/product/productInventory.vue") },
            { path: "/product/productPurchase", component: () => import("@/view/product/productPurchase.vue") },
          ]
        },
        {
          name: 'personalCenter',
          path: "/personalCenter",
          component: () => import("@/view/settings/personalCenter.vue"),
          meta: {
            title: "个人中心",
            fill: false,
          }
        },
        {
          name: "settings",
          component: emptyRouterView,
          path: '/settings',
          redirect: "/settings/company",
          meta: {
            title: "配置管理",
            menus: [
              // { title: "供应商管理", to: "/settings/company" },
              // { title: "平台成员管理", to: "/settings/personnel" },
              // { title: "基础数据", to: "/settings/basicData" },
            ]
          },
          children: [
            { path: "company", component: () => import("@/view/settings/company.vue") },
            { path: "personnel", name:'personnel', component: () => import("@/view/settings/personnel.vue") },
            { path: "basicData", component: () => import("@/view/settings/basicData.vue") },
            { path: "historyInfo", component: () => import("@/view/settings/historyInfo.vue") },
          ]
        },
        {
          path: "/reportForm",
          component: reportFormParent,
          name: "reportForm",
          meta: {
            title: "公司报表",
            menus: []
          },
          children: [
            // 报表列表
            {path: "/reportForm", component: () => import("@/view/reportForm/reportForm.vue")},
            // 里程碑
            {path: "/reportForm/milestone", component: () => import("@/view/reportForm/milestone.vue")},
            // 阶段
            {path: "/reportForm/stage", component: () => import("@/view/reportForm/stage.vue")},
            // 任务
            {path: "/reportForm/task", component: () => import("@/view/reportForm/task.vue")},
            // 工时
            {path: "/reportForm/workTimeLog", component: () => import("@/view/reportForm/workTimeLog.vue")},
            // 概览
            {path: "/reportForm/overview", component: () => import("@/view/reportForm/overview.vue")},
          ]
        },
      ]
    },
    {
      path: "/getOpenid",
      name: "getOpenid",
      component: () => import('@/view/settings/getOpenId.vue')
    },
    {
      name: 'dataScreen',
      path: "/dataScreen",
      component: () => import("@/view/dataScreen/index.vue"),
      meta: {
        title: '数据大屏'
      }
    }
  ]
})

export default router
