import { Spin } from "ant-design-vue"
import Vue from "vue"

// 加载指令v-loading
const loading = {
  bind(el, binding) {
    const spinContainer = document.createElement('div')
    const spinner = document.createElement('div')
    const spinNode = document.createElement('div')
    spinContainer.classList.add('v-loading-container')
    spinner.classList.add('v-loading-spinner')
    spinner.appendChild(spinNode)
    spinContainer.appendChild(spinner)
    el.appendChild(spinContainer)
    const parent = spinContainer.parentElement
    parent.classList.add('v-loading-container-parent')

    const { value } = binding
    el.instance = new Vue({
      data() {
        return {
          spinning: value,
          tip: '正在加载中，请耐心等待'
        }
      },
      render(h) {
        return h(Spin, {
          props: {
            spinning: this.spinning,
            tip: this.tip
          }
        })
      }
    }).$mount(spinNode)

  },

  update(el, binding) {
    const { value } = binding
    const spinContainer = el.querySelector('.v-loading-container')
    if (value) {
      spinContainer.style.display = 'flex'
    } else {
      spinContainer.style.display = 'none'
    }
    el.instance.spinning = value
  },

  unbind(el) {
    if (el.instance) {
      el.instance.$destroy()
      el.instance = null
    }
  }
}
export default loading
