<template>
  <div class="edp-card" v-bind="$attrs">
    <div class="edp-card-head" v-if="isHead">
      <div class="edp-card-head-left">
        <slot name="title">
          <div class="edp-card-head-left-title" v-if="title !== undefined">{{ title }}</div>
        </slot>
        <div class="edp-card-head-left-tabs" v-if="tabs.length > 0">
          <div
            class="edp-card-head-left-tabs-item"
            :class="{active: item.cat_code === tabActive}"
            v-for="(item, key) in autoTabs" :key="key"
            @click="handleTabClick(item)"
          >
            {{item.cat_name}}
          </div>
        </div>
      </div>
      <div class="edp-card-head-right">
        <slot name="head-end"></slot>
      </div>
    </div>
    <div class="edp-card-tools" :class="{'custom-form': customToolsForm}">
      <slot name="tools">
        <div class="empty-tools"></div>
      </slot>
    </div>
    <div class="edp-card-body" :class="bodyClass">
      <slot :active="tabActive"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "card",
  emits: ["change"],
  props: {
    isHead:{
      type: Boolean,
      default: true
    },
    // 标题
    title: {
      type: String
    },
    value: {
      type: String,
      default: () => ""
    },
    allow: {
      type: Boolean,
      default: () => true
    },
    // [{ cat_name: "", cat_code: "", cat_type: "" }] 基础数据数组
    // 两种格式的区别在于是否自定义active
    tabs: {
      type: Array,
      default: () => []
    },
    // 默认的选中项
    defaultTabActive: {
      type: [String, Number, Symbol]
    },
    bodyClass: {
      type: String,
      default: () => ""
    },
    customToolsForm: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      tabActive: ""
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val !== this.tabActive) {
          this.tabActive = val
        }
      }
    }
  },
  computed: {
    autoTabs() {
      if (this.tabs.length) {
        let result = [...this.tabs]
        if (this.allow) {
          result.unshift({cat_name: "全部", cat_code: ""})
        }
        return result
      }
      return []
    }
  },
  methods: {
    setTabActive(value) {
      this.tabActive = value
      let find = this.autoTabs.find(item => item.value === value)
      if (find) {
        this.handleTabClick(find)
      }
    },
    handleTabClick(item) {
      this.tabActive = item.cat_code
      this.$emit("input", item.cat_code)
      this.$emit("change", item.cat_code, item)
    }
  }
}
</script>

<style lang="less" scoped>
.edp-card {
  background-color: white;
  box-shadow: 0 2px 10px 0 rgba(182,182,182,0.50);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  &-head {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    box-sizing: content-box;
    border-bottom: 1px solid rgba(237,237,237,1);
    padding: 0 20px;
    flex-shrink: 0;
    &-left {
      display: flex;
      height: 100%;
      &-title {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        color: #4D4D4D;
        margin-right: 20px;
      }
      &-tabs {
        display: flex;
        &-item {
          font-weight: 400;
          display: flex;
          align-items: center;
          padding: 0 10px;
          cursor: pointer;
          font-size: 16px;
          color: #4D4D4D;
          margin-right: 5px;
          &.active {
            //font-size: 18px;
            color: var(--primary-color);
            font-weight: 700;
          }
        }
      }
    }
  }
  &-body {
  }
  /deep/ &-tools, /deep/ .card-tools-outlet {
    min-height: 60px;
    display: flex;
    align-items: center;
    &:not(.card-tools-outlet) {
      padding: 0 20px;
    }
    position: relative;
    z-index: 10;
    &:has(.empty-tools) {
      height: 0;
      display: none;
    }
    &:not(.custom-form) {
      >.right, .tools-right, >.line {
        .ant-input:not(.ant-calendar-picker-input) {
          width: 150px;
          border: none;
          border-radius: 4px 4px 4px 4px;
          background: #F3F3F3;
          font-size: 14px;
          color: #4D4D4D;
          line-height: 32px;

          &:hover {
            border-color: var(--primary-color);
          }

          &:focus {
            box-shadow: 0 0 0 1px var(--primary-color);
            background: #FFF;
          }
        }
        .ant-input-affix-wrapper {
          width: 150px;
          .ant-input{
            width: 100%;
          }
        }

        .ant-calendar-picker {
          width: 220px;
          .ant-input {
            border: none;
            background: #F3F3F3;
            input {
              color: #4D4D4D;
              &::placeholder {
                color: #909090;
              }
            }
          }
        }
        .ant-select {
          width: 150px;
          color: #4D4D4D;
          .ant-select-selection {
            border: none;
            border-radius: 4px 4px 4px 4px;
            background: #F3F3F3;
            .ant-select-selection__placeholder {
              color: #909090;
            }
          }
          &.ant-select-focused {
            box-shadow: 0 0 0 1px var(--primary-color);
            .ant-select-selection {
              background: #FFF;
            }
          }
        }
        .ant-btn.search {
          border-color: var(--primary-color);
          background-color: var(--primary-color);
          color: white;

        }
      }
    }
    >.right, .tools-right, >.line {
      &.right, &.tools-right {
        margin-left: auto;
      }
      display: flex;
      align-items: center;
      >.item {
        margin-right: 20px;
        display: flex;
        align-items: center;
        &:last-child {
          margin-right: 0;
        }
        .label {
          margin-right: 10px;
        }
      }
    }
    .ant-btn.search {
      border-color: var(--primary-color);
      background-color: var(--primary-color);
      color: white;
    }
  }
}
</style>
