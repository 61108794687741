<template>
  <a-empty :image="image">
  </a-empty>
</template>

<script>
import { Empty } from 'ant-design-vue';
export default {
  name: "edp-empty",
  computed: {
    image() {
      return Empty.PRESENTED_IMAGE_SIMPLE
    }
  }
}
</script>