import appsettings from "@/utils/appsettings.js";
import {message} from "ant-design-vue";
import {h} from "vue";
import Vue from "vue"
import {fetch} from "@/utils/request";

// eslint-disable-next-line no-unused-vars
// prefix: "img" | "api"
export function toUrl(src, append_token = true, prefix = "img") {
    if (typeof src !== 'string' || src.trim() === '') {
        return src
    }
    let temp = src
    if (!/^[a-zA-Z]+:\/\//.test(temp)) {
        if (temp.slice(0, 1) === "/") {
            temp = temp.slice(1)
        }
        if (prefix === "img") {
            temp = appsettings.imgPrefix + temp
        } else if (prefix === "api") {
            temp = appsettings.apiroot + temp
        }
    }
    // token获取方式暂时还没有 先注释
    if (append_token && !/[?|&]token=/.test(temp)) {
        if (/\?(&?\w+=.+)+$/.test(temp)) {
            temp = temp + "&token=" + localStorage.getItem("token")
        } else {
            temp = temp + "?token=" + localStorage.getItem("token")
        }
    }
    return temp
}

// 生成guid（默认是10位，可支持32位，36位guid的生成）
export function genGuid(bits = 10) {
    switch (bits) {
        case 10:
            return genGuid10()
        case 32:
            return genGuid32()
        case 36:
            return genGuid36()
        default:
            return ''
    }
}

function getRndInteger(min, max, maxIncluded = false) {
    if (maxIncluded) {
        // 包含最大值
        return Math.floor(Math.random() * (max - min + 1)) + min
    } else {
        // 不包括最大值
        return Math.floor(Math.random() * (max - min)) + min
    }
}

// 返回n位随机字符串
function getRandomString(bits) {
    let res = ''
    let chars = '0123456789abcdefghijklmnopqrstuvwxyz'
    let maxLen = chars.length

    for (let i = 0; i < bits; i++) {
        res += chars[getRndInteger(0, maxLen)]
    }
    return res
}

// 生成10位guid
function genGuid10() {
    let res = ''
    // 2位年份+8位随机
    let year = ((new Date()).getFullYear() + '').substr(2, 2)
    res = year + getRandomString(8)
    return res
}

// 生成32位guid
function genGuid32() {
    return getRandomString(32)
}

// 生成36位guid
function genGuid36() {
    let res = ''

    return res
}

// 格式化文件大小
export function renderSize(value) {
    if (null == value || value == '') {
        return "0";
    }
    let srcsize = parseFloat(value)
    let size = Math.floor(srcsize / 1024)
    return size
}

// 判断文件类型
export function checkFileType(name, type) {
    switch (name) {
        case 'img':
            return ['png', 'jpg', 'jpeg', 'gif'].indexOf(type.replace('.', '').toLowerCase()) !== -1;
        case 'word':
            return ['doc', 'docx'].indexOf(type.replace('.', '').toLowerCase()) !== -1;
        case 'pdf':
            return ['pdf'].indexOf(type.replace('.', '').toLowerCase()) !== -1;
        case 'excel':
            return ['xls', 'xlsx'].indexOf(type.replace('.', '').toLowerCase()) !== -1;
    }
}

export function customExpandIcon(props) {
    // 是否有子项，无子项不展示图标
    // record是每行的数据，后端返回的数据会有一个hasChild字段判断是project/list否有子项
    if (props.record && Array.isArray(props.record.children)) {
        // props.expanded a-table组件判断是否展开了为true是展开,false为没展开
        if (props.expanded) {
            // onClick事件必须添加上，相当于把a-table里的展开事件给了自定义的图标，不加的话点击会失效
            return h("i", {
                class: "iconfont icon-xiangxia expand-icon",
                on: {click: e => props.onExpand(props.record, e)}
            })
        } else {
            return h("i", {
                class: "iconfont icon-xiangxia expand-icon open",
                on: {click: e => props.onExpand(props.record, e)}
            })
        }
    } else {
        return h("span", {style: 'padding-left: 27px'})
    }
}

export function customExpandIconFlag(props) {
    // 是否有子项，无子项不展示图标
    // record是每行的数据，后端返回的数据会有一个hasChild字段判断是project/list否有子项
    if (props.record && props.record.end_flag === "1") {
        // props.expanded a-table组件判断是否展开了为true是展开,false为没展开
        if (props.expanded) {
            // onClick事件必须添加上，相当于把a-table里的展开事件给了自定义的图标，不加的话点击会失效
            return h("i", {
                class: "iconfont icon-xiangxia expand-icon",
                on: {click: e => props.onExpand(props.record, e)}
            })
        } else {
            return h("i", {
                class: "iconfont icon-xiangxia expand-icon open",
                on: {click: e => props.onExpand(props.record, e)}
            })
        }
    } else {
        return h("span", {style: 'padding-left: 27px'})
    }
}

// 查询子节点如果数组为空赋值为undefined
export function queryChildren(list) {
    return list.forEach(item => {
        // console.log(item);
        if (item?.end_flag !== '1') {
            item.children = undefined
        }
        if (item.children) {
            queryChildren(item.children)
        }
    })
}

export function updateOnefield(type = 'prj', field_id, field_name, field_value, oid, prjoid, old_value = "", new_value = "") {
    let url = type === 'prj' ? '/prj/update/onefield' : '/pro/update/onefield'
    return fetch('post', url, {field_id, field_name, field_value, oid, prjoid, old_value, new_value}).then((res) => {
        if (res.status === 0) {
            message.success('修改成功！')
            return Promise.resolve()
        } else {
            message.error(res.message)
        }
    })
}

// 格式化金额
Vue.filter("numberFormat", function (str) {
    if (str === undefined || str === null || isNaN(Number(str))) return str
    return Number(str).toLocaleString('zh', {maximumFractionDigits: 2, minimumFractionDigits: 0});
});

export function getTemplate(url, file_name) {
    return fetch('post', url, {file_name}).then((res) => {
        if (res.status === 0) {
            console.log(res.data);
            window.open(Vue.prototype.$toUrl(res.data))
        } else {
            message.error(res.message)
        }
    })
}

export function CatToCheckOptions(cat_list) {
    if (Array.isArray(cat_list)) {
        return cat_list.map(item => {
            return {
                label: item.cat_name,
                value: item.cat_code
            }
        })
    }
    return cat_list
}

export function ChildData2Vxe(list = [], parent_oid = undefined, deep = 0) {
  let data = []
  let maxDeep = deep

  list.forEach(item => {
    data.push({
      ...item,
      parent_oid,
      children: undefined,
    })
    if (item.children && item.children.length) {
      let temp = ChildData2Vxe(item.children, item.oid, deep + 1)
      data.push(...temp.data)
      maxDeep = Math.max(maxDeep, temp.maxDeep)
    }
  })

  return {
    data,
    maxDeep
  }
}