import Vue from 'vue'
import "swiper/swiper.css"
import App from './App.vue'
import Antd from 'ant-design-vue';
import router from "@/router/index.js";
import "@/assets/style/common.less"
import card from "@/components/card.vue";
import Storage from '@/utils/storage'
import appsettings from "@/utils/appsettings.js";
import {customExpandIcon, toUrl, genGuid, updateOnefield} from "@/utils/helpFunc.js";
import edpEmpty from "@/components/edp-empty.vue";
import prjPermissionModule from "@/utils/modules/prjPermissionModule";
import directives from './directives'
import './directives/index.less'
import VScaleScreen from 'v-scale-screen'
import dayjs from "dayjs"
import duration from 'dayjs/plugin/duration'
import json from "./version.json"
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

console.warn(JSON.stringify(json))
dayjs.extend(duration)
Vue.use(prjPermissionModule)
Vue.use(Antd)
Vue.use(directives)
Vue.use(VScaleScreen)
Vue.use(VXETable)
Vue.component("card", card)
Vue.component("edp-empty", edpEmpty)
// vuex
Vue.prototype.storage = Storage
// 配置挂载
Vue.prototype.$appsettings = appsettings
// 转换图片路径
Vue.prototype.$toUrl = toUrl
// 获取oid
Vue.prototype.$genGuid = genGuid
// 单字段更新
Vue.prototype.$updateOnefield = updateOnefield
// 全局使用loading
Vue.prototype.$setLoading = function (props) {
  if (typeof props === 'boolean') props = { spinning: props }
  if (Object.prototype.toString.call(props) !== '[object Object]') props = {}
  this.$app.loadingProps = {
    tip: '加载中...',
    ...props
  }
}
// 文件预览
Vue.prototype.$preview = (file, download = false) => {
  const preFileType = [
    ".jpg", ".jpeg", ".pdf", ".png", ".webp", ".gif", ".bmp", ".mp4", ".avi", ".wmv", "mp3", "wav"
  ]
  if (preFileType.includes(file.file_type)) {
    window.open(Vue.prototype.$toUrl(file.file_path+"?preview=p"))
  } else if (file.preview_url && file.preview_url !== "APPNULL") {
    window.open(Vue.prototype.$toUrl(file.preview_url+"?preview=p"))
  } else if (['.doc', '.docx'].includes(file.file_type)) {
    Vue.prototype.$message.info("正在生成在线预览")
  } else {
    if (download) {
      window.open(Vue.prototype.$toUrl(file.file_path))
    } else {
      Vue.prototype.$message.error("该文件类型暂不支持预览")
    }
  }
}

// 全局table使用自定义展开图标
Vue.prototype.$customExpandIcon = customExpandIcon

Vue.config.productionTip = false
window.mock = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
