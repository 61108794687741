<template>
  <div style="padding: 20px;" v-if="loading">
    {{text}}
  </div>
  <keep-alive include="projectReport" v-else>
    <router-view></router-view>
  </keep-alive>
</template>

<script>
export default {
  name: "projectParent",
  data() {
    return {
      loading: false,
      text: "loading..."
    }
  },
  watch: {
    "$route.query": {
      immediate: true,
      deep: true,
      handler(query, pre) {
        if (query?.prj_oid && pre?.prj_oid !== query?.prj_oid) {
          this.loading = true
          this.$store.dispatch("getPrjPermissions", query.prj_oid).then(() => {
            this.loading = false
            if (this.$route.name === 'projectParent') {
              let functions = this.$getP(query.prj_oid)
              let list = this.$router.currentRoute.meta.menus.filter(item => {
                return functions.includes(item.code) || !item.code
              }).map(item => {
                let to = typeof item.to === 'function' ? item.to(this.$route) : item.to
                return {
                  ...item,
                  to
                }
              })
              this.$router.replace({path: list[0]?.to})
            }
          })
        } else if (!query.prj_oid) {
          this.$router.replace({path: '/project/list'})
        }
      }
    }
  }
}
</script>